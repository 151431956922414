import { useQuery } from '@tanstack/vue-query'
import type { QueryOptions } from '~/src/types/QueryOptions'
import type { paths } from '~/src/api/schema'
import type { Cart } from '~/src/types/Cart'

export const GET_CART = '/api/carts/{id}'

export function getCart ({ params, query }: QueryOptions<paths[typeof GET_CART]['get']>) {
  const id = ref<string>()
  const organizationId = ref<string>()
  watchEffect(() => {
    id.value = toValue(params)!.path!.id
    organizationId.value = toValue(params)?.query?.organizationId
  })
  return useQuery({
    ...query,
    queryKey: [
      GET_CART,
      id,
      organizationId
    ],
    queryFn: async ({ signal }) => {
      if (!id.value) { return null }
      const client = useApi()
      const { data } = await client.GET(GET_CART, {
        params,
        signal
      })
      return data as Cart
    }
  })
}
